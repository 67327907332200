import React from "react";
import { useStore, StoreTypes } from "context";
import DragModal from "./DragModal";
import styles from "./index.module.scss";
import * as types from "constants/actionTypes";

const AiQuestionContent = () => {
  return (
    <>
      <div className={styles.aiQuestionContent}>
        <div className={styles.title}>使用說明</div>
        <div className={styles.step}>
          <span className={styles.bullet}>第 1 步</span>
          <div>點選「複製課文」，成功複製後，出現提示訊息。</div>
        </div>
        <div className={styles.step}>
          <span className={styles.bullet}>第 2 步</span>
          <div>
            點選「前往素養試題生成」，系統將另開啟網頁，在下方對話框內「貼上」已複製課文。
          </div>
        </div>
        <div className={styles.step}>
          <span className={styles.bullet}>第 3 步</span>
          <div>
            按下「Enter 箭頭 →」送出，稍等片刻，即可獲得 AI 生成的素養題型！
          </div>
        </div>
      </div>
    </>
  );
};

const AiQuestionBottom = ({
 source,
 link
}) => {
  const [, globalDispatch] = useStore(StoreTypes.global);

  // const [snackbarState, setSnackbarState] = useState({
  //   open: false,
  //   vertical: "top",
  //   horizontal: "center",
  // });
  // const { vertical, horizontal, open } = snackbarState;
  // const handleSnackbarClose = () => {
  //   setSnackbarState({ ...snackbarState, open: false });
  // };

  const copySource = () => {
    navigator.clipboard.writeText(source);
    globalDispatch({ type: types.SET_GROUP_INTERACTIVITY_SNACKBAR, payload: {message: '已成功複製課文', style: 'success', actionHandler: null }});
  };

  return (
    <>
      <div className={styles.actionsButton}>
        <div className={styles.secondaryButton} onClick={() => copySource()}>
          複製課文
        </div>
        <div className={styles.primaryButton}>
          <a href={link} target="_blank" rel="noopener noreferrer">
          前往素養試題生成
          </a>
        </div>
      </div>
    </>
  );
};

const AIQuestion = () => {
  const [
    {
      aiQuestion: { source, link },
    },
  ] = useStore(StoreTypes.reader);
  const [, globalDispatch] = useStore(StoreTypes.global);

  const handleClose = () => {
      globalDispatch({ type: types.CLOSE_AIQUESTION_MODAL });
  };


  return (
    <DragModal
      title='AI 素養試題生成'
      onCloseButtonClick={handleClose}
      content={
        <AiQuestionContent />
      }
      bottom={
        <AiQuestionBottom source={source} link={link} />
      }
    />
  );
};

export default AIQuestion;
