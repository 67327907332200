import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { PlatformType } from 'constants/platformType';
import { getPlatform } from 'util/platform';
import { LOGIN_POPUP } from "constants/loginTypes";

export const useAIQuestionCommand = () => {
    const [, globalDispatch] = useStore(StoreTypes.global);
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const [, loginDispatch] = useStore(StoreTypes.login);
    const [{token}] = useStore(StoreTypes.user);

    const execute = useCallback(async ({ json: { src, poeBotLink } }) => {
        // if(!token){
        //     loginDispatch({ type: LOGIN_POPUP, popupState: true });
        //     return;
        // }
        try {
            globalDispatch({ type: types.OPEN_AIQUESTION_MODAL })
            
            readerDispatch({type: types.SET_AIQUESTION_SOURCE, aiQuestionSource: src})
            readerDispatch({type: types.SET_AIQUESTION_BOTLINK, poeBotLink})

        } catch (e) {
            console.error('AIQuestionCommand execute error', e);
        }
    }, [globalDispatch, readerDispatch, token]);

    return { execute };
};