import React, { useMemo } from "react";
import { Button, IconButton, makeStyles, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { useStore, StoreTypes } from "context";
import * as types from "constants/actionTypes";

const useSnackbarStyle = makeStyles({
  root: {
    top: "1em",
  },
});

const useInfoAlertStyle = makeStyles({
  root: {
    alignItems: "center",
    background: "#D0F2FF",
    borderWidth: "2px",
    fontWeight: "700",
    padding: "0em .5em",
    borderRadius: ".5em",
    fontSize: "1.2em",
    minWidth: "600px",
  },
});

const useActionButtonStyle = makeStyles({
  root: {
    color: "white",
    padding: ".2em .8em",
    background: "#B7601A",
    borderRadius: ".5em",
    width: "7em",
    minWidth: "7em",
    height: "2.5em",
  },
});

const useSecondaryAlertStyle = makeStyles({
  root: {
    alignItems: "center",
    background: "#FFD285",
    border: "2px solid #B7601A",
    fontWeight: "700",
    fontSize: "1.2em",
    color: "#7A310A",
    padding: "0em .5em",
    borderRadius: ".5em",
    minWidth: "600px",
    "& .MuiAlert-icon": {
      color: "#B7601A",
    },
  },
});

const useSuccessAlertStyle = makeStyles({
  root: {
    alignItems: "center",
    background: "#CEFCD4",
    borderWidth: "2px",
    fontWeight: "700",
    padding: "0em .5em",
    borderRadius: ".5em",
    fontSize: "1.2em",
    minWidth: "600px",
  },
});

export const SnackbarForInteractivity = () => {
  const snackbarStyles = useSnackbarStyle();
  const infoAlertStyle = useInfoAlertStyle();
  const secondaryAlertStyle = useSecondaryAlertStyle();
  const actionButtonStyle = useActionButtonStyle();
  const successAlertStyle = useSuccessAlertStyle();
  const [
    {
      groupInteractivity: { snackbar },
    },
    globalDispatch,
  ] = useStore(StoreTypes.global);

  const alertStyle = useMemo(() => {
    switch (snackbar.style) {
      case "info":
        return infoAlertStyle;
      case "secondary":
        return secondaryAlertStyle;
      case "success":
        return successAlertStyle;
      default:
        return;
    }
  }, [snackbar.style]);

  const handleClose = () => {
    globalDispatch({
      type: types.SET_GROUP_INTERACTIVITY_SNACKBAR,
      payload: { message: "", style: "info", actionHandler: null },
    });
  };

  return (
    <Snackbar
      classes={snackbarStyles}
      open={snackbar.message}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "vertical" }}
      onClose={(e, reason) => {
        if (reason === "timeout") {
          handleClose();
        }
      }}
    >
      <MuiAlert
        classes={alertStyle}
        variant="outlined"
        severity={snackbar.style === 'success' ? 'success' : 'info'}
        action={
          snackbar.actionHandler ? (
            <Button
              classes={actionButtonStyle}
              onClick={snackbar.actionHandler}
            >
              前往新筆記
            </Button>
          ) : (
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          )
        }
      >
        {snackbar.message}
      </MuiAlert>
    </Snackbar>
  );
};
